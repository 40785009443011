import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  SiteLink,
  BG,
  Footer,
  Social,
  Products,
  Item,
  ItemWrapper,
} from './preorder.css';

import Instagram from '../../../static/instagramBlack.svg';
import Facebook from '../../../static/facebookBlack.svg';
import Twitter from '../../../static/twitterBlack.svg';
import Messenger from '../../../static/messengerBlack.svg';

import LonelyHero from '../../../static/imlonely.jpg';
import LonelyHeroMobile from '../../../static/lonely-hero-mobile.jpg';
import LauvPreorderLogo from '../../../static/lauv-preorder-logo.png';
import HIFLogo from '../../../static/hif-logo.png';

import Bundle1 from '../../../static/Bundle1_AllVinyl.png';
import Bundle2 from '../../../static/Bundle2_VinylAll.png';
import Bundle3 from '../../../static/Bundle3_VinylAll.png';
import Bundle4 from '../../../static/Bundle4VinylClear.png';

// Icons
import Globe from '../../../static/globe.svg';
import USFlag from '../../../static/usa-flag.svg';
import Amazon from '../../../static/amazon-brands.svg';
import iTunes from '../../../static/itunes-note-brands.svg';
import Apple from '../../../static/apple-brands.svg';
import Spotify from '../../../static/spotify-brands.svg';

const PRODUCTS = [
  {
    id: 1,
    image: Bundle1,
    name: 'FUN PACK',
    link: 'https://lauvpreorder.com',
    linkRow: 'https://lauv.tmstor.es',
  },
  {
    id: 2,
    image: Bundle2,
    name: 'REALLY FUN PACK',
    link: 'https://lauvpreorder.com',
    linkRow: 'https://lauv.tmstor.es',
  },
  {
    id: 3,
    image: Bundle3,
    name: 'REALLY REALLY FUN PACK',
    link: 'https://lauvpreorder.com',
    linkRow: 'https://lauv.tmstor.es',
  },
  {
    id: 4,
    image: Bundle4,
    name: 'MUSIC PACK',
    link: 'https://lauvpreorder.com',
    linkRow: 'https://lauv.tmstor.es',
  },
];

const Hero = () => {
  return (
    <Fragment>
      <BG
        song={LonelyHero}
        mobileBg={LonelyHeroMobile}
        bgColor="#eff1f1"
        name="lonely"
      >
        <div className="hifWrapper">
          <div className="promoPhoto" />
          <div className="promoLinks">
            <img
              src={LauvPreorderLogo}
              alt="Lauv"
              className="lauvLogo"
              width="226"
            />

            <img src={HIFLogo} alt="How I Feel Logo" />
            <div className="avail">Available March 6th</div>

            <div className="orderWrapper">
              <div className="orderTitle">PHYSICAL PRE-ORDER</div>
              <div className="orderBtns">
                <a href="https://lauvpreorder.com">
                  <img src={USFlag} alt="USA Pre-order" />
                  ALBUM PRE-ORDER US
                </a>
                <a href="https://lauv.tmstor.es">
                  <img src={Globe} alt="World Pre-order" />
                  ALBUM PRE-ORDER WORLD
                </a>
              </div>
            </div>
            <div className="orderWrapper">
              <div className="orderTitle">Stream and Download</div>
              <div className="orderBtns">
                <a
                  href="https://ffm.to/howimfeeling/spotify"
                  rel="noopener noreferrer"
                  target="_blank"
                  id="spotify_PO_DSP_BTN"
                >
                  <img src={Spotify} alt="Spotify Pre-order" />
                  Spotify Pre-Save
                </a>
                <a
                  href="https://lauv.lnk.to/hifpreadd"
                  rel="noopener noreferrer"
                  target="_blank"
                  id="applemusic_PO_DSP_BTN"
                >
                  <img src={Apple} alt="Apple Pre-order" />
                  Apple Music Pre-Add
                </a>
              </div>
              <div className="orderBtns">
                <a
                  href="https://ffm.to/howimfeeling/itunes"
                  target="_blank"
                  rel="noopener noreferrer"
                  id="itunes_PO_DSP_BTN"
                >
                  <img src={iTunes} alt="Amazon Pre-order" />
                  iTunes Pre-Order
                </a>
                <a
                  href="https://ffm.to/howimfeeling/amazonstore"
                  target="_blank"
                  rel="noopener noreferrer"
                  id="amazonmusic_PO_DSP_BTN"
                >
                  <img src={Amazon} alt="Amazon Pre-order" />
                  Amazon Pre-Order
                </a>
              </div>
            </div>
          </div>
        </div>
      </BG>

      <Footer>
        <Social>
          <a
            rel="noopener noreferrer"
            href="https://www.instagram.com/lauvsongs/"
            target="_blank"
            id="instagram_Social_BTN"
          >
            <img src={Instagram} alt="Lauv on Instagram" />
          </a>
          <a
            rel="noopener noreferrer"
            href="https://www.facebook.com/lauvsongs/"
            target="_blank"
            id="facebook_Social_BTN"
          >
            <img src={Facebook} alt="Lauv on Facebook" />
          </a>
          <a
            rel="noopener noreferrer"
            href="https://twitter.com/lauvsongs"
            target="_blank"
            style={{ position: 'relative', top: '1px' }}
            id="twitter_Social_BTN"
          >
            <img src={Twitter} alt="Lauv on Twitter" />
          </a>
          <a
            rel="noopener noreferrer"
            href="https://m.me/lauvsongs"
            target="_blank"
            style={{ position: 'relative', top: '1px' }}
            id="facebookMessenger_Social_BTN"
          >
            <img src={Messenger} alt="Lauv on Facebook Messenger" />
          </a>
        </Social>

        <SiteLink href="https://lauvsongs.com">LauvSongs.com</SiteLink>
      </Footer>
    </Fragment>
  );
};

export default Hero;

const Product = ({ productImg, link, productName, linkRow }) => (
  <ItemWrapper>
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className="imgLink"
    >
      <Item productImg={productImg} />
    </a>
    <div className="productName">{productName}</div>

    <a href={link} target="_blank" rel="noopener noreferrer" className="buyBtn">
      Purchase US
    </a>
    <a
      href={linkRow}
      target="_blank"
      rel="noopener noreferrer"
      className="buyBtn"
    >
      Purchase World
    </a>
  </ItemWrapper>
);

Product.propTypes = {
  productImg: PropTypes.string,
  link: PropTypes.string,
  productName: PropTypes.string,
  linkRow: PropTypes.string,
};
